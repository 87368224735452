
import {Component, Vue, Prop} from 'vue-property-decorator';
import Joypad from "@/components/Joypad2.vue";
import {pad} from "@/helpers/helpers";

@Component({
    name: "JoypadGameScreen",
    components: {Joypad},
})
export default class JoypadGameScreen extends Vue {
    // props
    @Prop(Object) state!: any;
    @Prop(Object) parameters!: any;

    // data
    configuration = this.$store.state.configuration;

    // computed
    get formattedState(): string {
        return JSON.stringify(this.state, null, 4);
    }

    get characterId(): number {
        if(this.state && (typeof this.state.CharacterId === "number") && (this.state.CharacterId >= 0)) {
            return this.state.CharacterId + 1;
        }
        else {
            return 1;
        }
    }

    get jetpackId(): number {
        if(this.state && (typeof this.state.JetpackId === "number") && (this.state.JetpackId >= 0)) {
            return this.state.JetpackId + 1;
        }
        else {
            return 1;
        }
    }

    get color(): number {
        if(this.state && (typeof this.state.color === "number") && (this.state.color >= 0)) {
            return this.state.color;
        }
        else {
            return 1;
        }
    }

    get playerStyle(): any {
        if(this.state && this.state.ScreenPosX && this.state.ScreenPosY) {
            return {
                backgroundImage: "url(/characters/" + pad(this.characterId, 2) + "/" + pad(this.color, 4) +".png)",
                left: (this.state.ScreenPosX * 100).toFixed(0) + "%",
                top: ((1 - this.state.ScreenPosY) * 100).toFixed(0) + "%"
            }
        }
        else {
            return {
                backgroundImage: "url(/characters/CharacterType0" + this.configuration.characterId + this.configuration.color + this.configuration.jetpackId + ".png)",
                left: "50%",
                top: "50%"
            };
        }
    }

    get energyStyle(): any {
        if(this.state && this.state.energie) {
            return {
                width: (this.state.energie * 100).toFixed(0) + "%"
            }
        }
        else {
            return {};
        }
    }

    get bonusStyle(): any {
        if(this.state && (typeof this.state.bonus === "number") && (this.state.bonus >= 0)) {
            return {
                display: "block",
                backgroundImage: "url(/bonus/bonus" + this.state.bonus + ".png)"
            };
        }
        else {
            return {
                display: "none"
            };
        }
    }

    get map(): string {
        if ((this.color === 2) || (this.color === 3) || (this.color === 4) || (this.color === 5)) {
            if ((this.color === 2) || (this.color === 4)) {
                return (this.state && this.state.map) ? this.state.map : "map2";
            }
            else {
                return (this.state && this.state.map) ? this.state.map : "map1";
            };
        }
        else {
            return (this.state && this.state.map) ? this.state.map : "default";
            };
    }

    get mapPath(): string {
        if(this.parameters && this.parameters.maps) {
            return this.parameters.maps[this.map];
        }
        else {
            return this.map;
        }
    }

    get playgroundStyle(): any {
        if(this.state && (typeof this.state.bonus)) {
            return {
                backgroundImage: "url(" + this.mapPath + ")"
            };
        }
    }

    // methods
    startButton(type: string) {
        this.$emit("startButton", type);
    }
    stopButton(type: string) {
        this.$emit("stopButton", type);
    }

    joystick() {
        this.$emit("joystick", Math.min(3, Math.floor(Math.random() * 4)));
    }

    joypadMove(position: any) {
        this.$emit("joystick", position);
    }

    // watch

    // hooks
}
